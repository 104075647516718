import Row from 'react-bootstrap/Row';

function TopContent(props: any) {
  return (
    <>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{height: 'auto', width: '70%', padding: 0}}>
                <p
                    style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    {props.firstDescription}
                </p>
            </div>
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
            <div style={{height: 'auto', width: '60%', padding: 0}}>
                <p
                    style={{
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    {props.secondDescription}
                </p>
            </div>
        </Row>
    </>
  );
}

export default TopContent;