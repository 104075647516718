import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

function InfoBox(props: any) {
  return (
    <Card style={{height: '100%', backgroundColor: '#F7FAFC', border: '1px solid #CFDEE8'}}>
        <Card.Body>
            <img src={props.icon} width={20} height={20} style={{marginBottom: 10}}/>
            <Card.Title>
                <p style={{fontWeight: 'bold', fontSize: 16, padding: 0, margin: 0}}>{props.title}</p>
            </Card.Title>
            <Card.Text>
                <p style={{fontSize: 14, color: '#4A789C', padding: 0, margin: 0}}>{props.description}</p>
            </Card.Text>
        </Card.Body>
    </Card>
  );
}

function BottomContent(props: any) {
  return (
    <>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{height: 'auto', width: '40%', padding: 0}}>
                <p
                    style={{
                        fontWeight: 'bold',
                        fontSize: 24,
                        textAlign: 'center',
                    }}
                >
                    {props.description}
                </p>
            </div>
        </Row>

        <Row style={{height: 'auto'}}>
            {props.infoList.map((info:any) => (
                <Col sm><InfoBox {...info}/></Col>
            ))}
        </Row>
    </>
  );
}

export default BottomContent;