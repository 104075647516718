import React from 'react';
import ReactDOM from 'react-dom/client';
import { clarity } from 'react-microsoft-clarity';

import './index.css';
import App from './Containers';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

clarity.init('mso94geahl');
clarity.identify('USER_ID', { userProperty: 'value' });

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
