import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Media(props: any) {
  return (
    <>
        <Row style={{display: 'flex', justifyContent: 'center', cursor: 'pointer'}}>
            {props.mediaList.map((info:any) => (
                <Col xs="auto" style={{padding: 1, margin: 5}}>
                    <a href={info.url} target="_blank"><img src={info.icon} width={25} height={25}/></a>
                </Col>
            ))}
        </Row>
    </>
  );
}

export default Media;