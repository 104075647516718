import Spinner from 'react-bootstrap/Spinner';

function Loading(props: any) {
  return (
    <div
      style={{
        position: 'fixed',
        backgroundColor: 'rgba(255,255,255,0.70)',
        zIndex: 9999,
        height: '100vh',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        visibility: props.isLoading ? 'visible' : 'hidden',
      }}
    >
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="primary" />
    </div>
  );
}

export default Loading;
