import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

function Input(props: any) {
  return (
    <InputGroup>
      <Form.Control
        required
        type="text"
        aria-label="Your email address"
        onChange={(event) => props.onChange(event.target.value)}
        value={props.value}
        placeholder={props.placeholder}
        style={{
          border: '1px solid #4A789C',
          backgroundColor: '#F7FAFC',
          color: 'black'
        }}
      />
      <Button variant={props.actionConfig.variant} 
        onClick={async () => {
          if (props.value === null || props.value === '') return;

          const result = await props.actionConfig.action(props.value);
          if(result) {
            props.onChange('');
          }
        }}
        style={{padding: 10, border: '1px solid #4A789C'}}
      >
          {props.actionConfig.title}
      </Button>
    </InputGroup>
  );
}

export default Input;
