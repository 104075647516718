import logo from '../Assets/logo.png';

function LogContent(props: any) {
  return (
    <>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 25
          }}
        >
          <img src={logo}/>
        </div>

        <hr />
    </>
  );
}

export default LogContent;