import axios from 'axios';

const TIMEOUT = 1000;

const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const saveData = (props: any) => async (email: string) => {
    if (email === null || email === ''){
        return Promise.resolve(false);
    }
    if(!validateEmail(email)){
        return Promise.resolve(false);
    }
    props.setLoading(true);
    try {
        const response = await axios.post('https://crowdtiv.com:5000/notion-save-data', {email});

        setTimeout(() => {
            props.setLoading(false);
            props.setMainPage(false);
        }, TIMEOUT);

        return Promise.resolve(true);
    } catch (error) {
        setTimeout(() => {
            props.setLoading(false);
            props.setMainPage(true);
        }, TIMEOUT);

        return Promise.resolve(false);
    }

    return Promise.resolve(false);
}