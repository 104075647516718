import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import TopContent from './TopContent';
import LogoContent from './Logo';
import BottomContent from './BottomContent';
import Loading from './Loading';
import Input from './Input';
import Media from './Media';
import {
  BRAND_NAME,
  FIRST_DESCRIPTION,
  SECOND_DESCRIPTION,
  DESCRIPTION,
  INPUT_PLACEHOLDER,
} from '../Locales';
import globe from '../Assets/globe.png';
import announcement from '../Assets/announcement.png';
import idea from '../Assets/idea.png';
import community from '../Assets/community.png';
import discord from '../Assets/discord.png';
import instagram from '../Assets/instagram.png';
import {saveData} from './handler';

const discordUrl = 'https://discord.gg/5yyypEj43c';
const instagramUrl = 'https://www.instagram.com/crowdtiv/?utm_source=waitlist';

const SuccessInput = (props: any) => {
  return (
    <Row style={{display: 'flex', justifyContent: 'center'}}>
      <p style={{fontSize: 60, marginBottom: 40, marginTop: 20, textAlign: 'center'}}>Yay! you’re on the waitlist!</p>
      <p style={{fontSize: 30, textAlign: 'center', width: '50%'}}>
        Follow our
        <span style={{color: '#4A789C'}}><a style={{cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none'}} href={instagramUrl} target="_blank"> social media </a></span>
        and join our
        <span style={{color: '#4A789C'}}><a style={{cursor: 'pointer', fontWeight: 'bold', textDecoration: 'none'}} href={discordUrl} target="_blank"> discord </a></span> 
        to be the first part of this community!
      </p>
    </Row>
  )
}

function MainContainer(props: any) {
  const [text, setText] = React.useState('');
  const [mainPage, setMainPage] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <Loading isLoading={loading}/>
      <Container>
        <div style={{}}>
          <LogoContent brandName={BRAND_NAME}/>
        </div>

        {mainPage ?
        (<>
          <TopContent
            firstDescription={FIRST_DESCRIPTION}  
            secondDescription={SECOND_DESCRIPTION}
          />
          <div style={{marginTop: 30}}>
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{height: 'auto', width: '55%', padding: 0}}>
                <Input 
                  actionConfig={{
                    variant: 'primary',
                    action: saveData({...props, setMainPage, setLoading}),
                    title: 'Join Waitlist'
                  }}
                  placeholder={INPUT_PLACEHOLDER}
                  value={text}
                  onChange={setText}
                />
              </div>
            </Row>
          </div>

          <div style={{marginTop: 40}}>
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <a href="https://www.producthunt.com/posts/crowdtiv?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-crowdtiv" target="_blank" style={{alignItems: 'center', alignSelf: 'center', textAlign: 'center'}}>
                <img 
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=462389&theme=light" alt="Crowdtiv - Social&#0032;Network&#0032;Mentoring&#0032;Platform | Product Hunt"
                    style={{width: 240, height: 44}}
                    width={250}
                    height={54}
                />
              </a>
            </Row>
          </div>

          <div style={{marginTop: 30, marginBottom: 50}}>
            <BottomContent
              description={DESCRIPTION}
              infoList={[
                {
                  icon: globe,
                  title: 'Accessibility for All',
                  description: 'We believe in reducing the knowledge gap globally, making it easier for everyone to access expert mentorship and support.'
                },
                {
                  icon: announcement,
                  title: 'Scale Your Brand',
                  description: 'Mentors in our community don\'t just teach—they grow. Enhance your visibility and personal brand through our network.'
                },
                {
                  icon: community,
                  title: 'Engaging Community',
                  description: 'Connect with like-minded individuals and mentors in an active, supportive environment where every interaction adds value.'
                },
                {
                  icon: idea,
                  title: 'Impactful Opportunities',
                  description: 'Participate in events, workshops, and sessions that are designed to provide practical skills and knowledge to propel you and your brand forward.'
                }
              ]}
            />
          </div>
        </>) : (<SuccessInput />)}

        <div style={{marginTop: 10, marginBottom: 50}}>
          <Media mediaList={[
            {icon: discord, url: discordUrl},
            {icon: instagram, url: instagramUrl}
          ]}/>
        </div>
      </Container>
    </>
  );
}

export default MainContainer;
