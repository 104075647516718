export const BRAND_NAME = 'Crowdtiv';

// TOP CONTENT
export const FIRST_DESCRIPTION = 'Join Our Waitlist for Exclusive Mentorship and Community Building';
export const SECOND_DESCRIPTION = 'Are you ready to bridge the knowledge gap and amplify your personal branding? Join our waitlist today and become a part of a transformative community';

// INPUT
export const INPUT_PLACEHOLDER = 'Your email address';

// BOTTOM CONTENT
export const DESCRIPTION = 'Why Crowdtiv?'